import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import ImageInfo from "../components/image-info"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Jean Maeriën" />
    <div style={{ maxWidth: `960px`, marginBottom: `6.45rem`, marginTop: `2.45rem` }}>
      <Image />
    </div>
    <div style={{ maxWidth: `960px`, marginBottom: `6.45rem`, marginTop: `2.45rem` }}>
      <ImageInfo />
    </div>
    <div>
      <iframe 
      title="inschrijven"
      class="airtable-embed airtable-dynamic-height" 
      src="https://airtable.com/embed/shrPrGI1HFvxq8ntz?backgroundColor=cyan" 
      frameborder="0" 
      onmousewheel="" 
      width="100%" 
      height="755" 
      style={{background: 'transparent', border: '1px solid #ccc',  overflow: 'hidden'}}></iframe>
    </div>
  </Layout>
)

export default IndexPage
